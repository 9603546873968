<template>
  <div class="feed" :class="{ active: show }">
    <h2 class="feed-title">Notifications</h2>
    <div class="feed-close" @click="$emit('close')">
      <img src="@/assets/images/icons/xmark.svg" alt="" />
    </div>
    <div class="feed-message-list">
      <div
        v-for="(feed, index) of feedList"
        :key="index"
        :ref="`${feed.id}`"
        class="feed-message"
        :data="feed.id"
      >
        <div class="feed-message-remove" @click="markAsSeen(feed)">
          <img src="@/assets/images/icons/xmark.svg" alt="" />
        </div>
        <div class="feed-message-text">
          <span>{{ feed.date }}</span>
          <h3>{{ feed.title }}</h3>
          <p>{{ feed.text }}</p>
        </div>
        <div v-if="feed.orderID" class="feed-message-buttons">
          <b-button variant="success" @click="confirm(feed)">
            <img src="@/assets/images/icons/check.svg" alt="" />
          </b-button>
        </div>
      </div>
      <h4 class="feed-message-list-empty" v-if="!feedList.length">
        No more messages
      </h4>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: { type: Boolean, required: true },
  },
  data() {
    return {}
  },
  computed: {
    feedSize() {
      return this.$store.state.feed.length
    },
    feedList() {
      return this.$store.state.feed
    },
  },
  watch: {
    show(value) {
      if (value) {
        this.$store.dispatch('setFeedChangeFlag', false)
      }
    },
  },
  methods: {
    markAsSeen(feed) {
      if(feed.orderID) {
        this.confirm(feed)
      }
      this.$store.dispatch('removeFromFeed', feed)
    },
    confirm(feed) {
      this.$Orders
        .updateResourceByUrl({
          url: feed.orderID,
          body: { confirmed: true },
        })
        .then(() => {
          feed.orderID = null
          this.$store.dispatch('updateFeedMessage', feed)
        })
    },
  },
}
</script>
