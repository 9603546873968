<template>
  <div class="courier-stock">
    <title-bar title="Control of items" />

    <b-alert :show="onOff === false || car === null" variant="warning"
      >You are not Available or you have no Car assigned</b-alert
    >

    <b-button
      v-if="onOff === true && car !== null"
      @click="confirm"
      class="make-order-btn"
      variant="success"
      >Confirm</b-button
    >

    <div v-if="onOff === true && car !== null">
      <b-form-checkbox
        class="mb-4"
        v-model="allSelected"
        :indeterminate="indeterminate"
        @change="toggleAll"
      >
        {{ allSelected ? 'Deselect all' : 'Select all' }}
      </b-form-checkbox>

      <div v-for="(category, categoryIndex) of categories" :key="categoryIndex">
        <div v-if="category.products.length" class="product-listing top-border">
          <div class="product-listing-title">{{ category.name }}</div>
          <div
            v-for="(product, index) of category.products"
            :key="index"
            class="product-listing-item"
          >
            <div class="product-checkbox">
              <b-form-checkbox
                v-model="selected"
                :value="product['@id']"
              ></b-form-checkbox>
            </div>
            <div class="product">
              <div class="product-image">
                <img src="" alt="" />
              </div>
              <div class="product-name">{{ product.name }}</div>
            </div>
            <div class="stock-count oci">+{{ product.oci - getProductStockCount(product) }}</div>
            <div class="stock-count">{{ getProductStockCount(product) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import Toast from '@/components/Toast.vue'

export default {
  components: {
    TitleBar,
  },
  data() {
    return {
      selected: [],
      allSelected: false,
      indeterminate: false,
      ids: null,
      oci: null,
      userData: JSON.parse(localStorage.getItem('userData')),
      onOff: this.$store.state.onOff,
      categories: null,
      products: null,
      car: null,
    }
  },
  created() {
    this.loadCategories()
    this.$Cars
      .getCollection({ params: { courier: this.userData['@id'] } }, 'car_list')
      .then(carResponse => {
        if (carResponse.data['hydra:member'].length) {
          // eslint-disable-next-line prefer-destructuring
          this.car = carResponse.data['hydra:member'][0]
          this.loadStock()
        }
      })
  },
  watch: {
    selected(newValue) {
      if (newValue.length === 0) {
        this.indeterminate = false
        this.allSelected = false
      } else if (newValue.length === this.ids.length) {
        this.indeterminate = false
        this.allSelected = true
      } else {
        this.indeterminate = true
        this.allSelected = false
      }
    },
  },
  methods: {
    loadStock() {
      if (this.categories && this.categories.length) {
        this.categories.forEach(category => {
          category.products = []
        })
      }
      const params = {
        pagination: false,
      }
      if (this.search !== '') {
        params.search = this.search
      }
      this.$ProductVariants
        .getCollection({ params }, 'product_variant_list')
        .then(async response => {
          const ociReposne = await this.loadOCI()
          if (ociReposne.status === 200) {
            this.oci = ociReposne.data['hydra:member']
          }
          this.products = response.data['hydra:member']
          this.ids = this.products.map(product => product['@id'])
          // eslint-disable-next-line no-restricted-syntax
          for(const product of this.products ){
            const category = this.categories.find(
              cat => cat['@id'] === product.category,
            )
            const oci = this.oci.find(
              o => o.variant === product['@id'],
            )
            product.oci = oci.quantity
            category.products.push({ ...product, toOrder: 0 })
          }
        })
    },
    loadCategories() {
      this.$Categories.getCollection({}, 'category_list').then(response => {
        this.categories = response.data['hydra:member']
        this.categories = this.categories.map(category => ({
          ...category,
          products: [],
        }))
      })
    },
    loadOCI () {
      return this.$OptimalCarInventories.getCollection({
        params: { pagination: false, size: this.car.size },
      }, 'car_list')
    },
    toggleAll(checked) {
      this.selected = checked ? this.ids.slice() : []
    },
    getProductStockCount(product) {
      const stock = product.inventories.find(
        inv => inv.car === this.car['@id'],
      )
      return stock.available
    },
    async confirm() {
      const productsToUpdate = []
      const ociResponse = await this.$OptimalCarInventories.getCollection({
        params: { pagination: false, size: this.car.size },
      }, 'optimal_car_inventories_list')

      // TODO - warehouse natvrdo
      const warehouse = this.products[0].inventories.find(i => i.warehouse)

      this.selected.forEach(p => {
        const oci = ociResponse.data['hydra:member'].find(
          o => o.variant === p,
        )
        const product = this.products.find(pr => pr['@id'] === p)

        if (oci) {
          const available = this.getProductStockCount(product)
          if (available !== oci.quantity) {
            productsToUpdate.push({
              productVariantId: product.id,
              quantity: oci.quantity - available,
            })
          }
        }
      })

      if (productsToUpdate.length) {
        const body = {
          warehouse: warehouse.warehouse,
          car: this.car['@id'],
          products: productsToUpdate,
        }

        this.$AdjustInventories
          .createResource({ body })
          .then(() => {
            this.$helper.showToast(
              this.$toast,
              Toast,
              'Optimal number of pieces for selected products loaded',
              '',
              'success',
            )
            this.selected = []
            this.loadStock()
          })
          .catch(error => {
            if (error.response) {
              this.$helper.showToast(
                this.$toast,
                Toast,
                error.response.data['hydra:title'],
                error.response.data['hydra:description'],
                'danger',
              )
            }
          })
      } else {
        this.$helper.showToast(
          this.$toast,
          Toast,
          'Car is already optimally loaded',
          '',
          'success',
        )
      }
    },
  },
}
</script>
