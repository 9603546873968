<template>
  <div class="title-bar">
    <h1>{{ title }}</h1>
    <div class="title-bar__buttons">
      <b-button v-if="storeBtn" variant="success" @click="storeFromStock">
        <img src="@/assets/images/icons/stock.svg" alt="" />
      </b-button>
      <b-button v-if="feedSize" variant="success" class="ml-2 feed-btn" @click="showFeed = true" :class="{pulse: feedChangedFlag}">
        <img src="@/assets/images/icons/message-square.svg" alt="" />
        <span>{{ feedSize }}</span>
      </b-button>
    </div>
    <feed :show="showFeed" @close="showFeed = false" />
  </div>
</template>

<script>
import Feed from '@/components/Feed.vue'

export default {
  name: 'TitleBar',
  components: {
    Feed
  },
  props: {
    title: String,
    storeBtn: Boolean,
  },
  data() {
    return {
      showFeed: false
    }
  },
  computed: {
    feedSize() {
      return this.$store.state.feed.length
    },
    feedChangedFlag() {
      return this.$store.state.feedChangedFlag
    },
  },
  methods: {
    storeFromStock() {
      this.$emit('storeFromStock')
    },
  },
}
</script>
